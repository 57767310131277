import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import Faq from "../components/faq";
import Hero from "../components/hero";
import Layout from "../components/layout";
import Logos from "../components/logos";
import OSSection from "../components/other-services-section";
import OASection from "../components/our-approach-section";
import ReviewSection from "../components/review-section";
import parse from "html-react-parser";

const WebDesignPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
				altText
				mediaDetails {
					height
					width
				}
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			img1: wpMediaItem(title: { eq: "Web-Design-New" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			capitalImg: wpMediaItem(title: { eq: "capitaltocoast-fullpage" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			hiveImg: wpMediaItem(title: { eq: "Hivehr-fullpage" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			googleAImg: wpMediaItem(title: { eq: "google-analytics-new" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			googleSearchImg: wpMediaItem(title: { eq: "google-search" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			wpSeoPage(title: { eq: "Web Design" }) {
				contentForHeadTag {
					content
				}
				seo {
					metaDesc
					title
					twitterImage {
						altText
						mediaDetails {
							height
							width
						}
						link
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
					opengraphTitle
					opengraphDescription
				}
			}
			icon: wpMediaItem(title: { eq: "icon" }) {
				link
			}
			wpWebDesignPage {
				webDesignPageFields {
					bookAMeetingSection {
						title
						description
					}
					creatingSection {
						title
						description
						button1 {
							text
							link
						}
						image {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
					heroSection {
						title
						description
						button1 {
							text
							link
						}
					}
					ourApproachSection {
						button {
							link
							text
						}
						title
						image {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
						step1 {
							title
							description
						}
						step2 {
							title
							description
						}
						step3 {
							title
							description
						}
						step4 {
							title
							description
						}
					}
					weDeliverSection {
						title
						description
						button1 {
							text
							link
						}
						image1 {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
						image2 {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
						image3 {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const schema = data.wpSeoPage.contentForHeadTag.content;
	const {
		wpWebDesignPage: {
			webDesignPageFields: {
				bookAMeetingSection,
				creatingSection,
				heroSection,
				ourApproachSection,
				weDeliverSection,
			},
		},
	} = data;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Web Design",
				item: {
					url: `${siteUrl}/web-design`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				{" "}
				<script type="application/ld+json">{schema}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={data.wpSeoPage.seo.title}
				description={data.wpSeoPage.seo.metaDesc}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/web-design`,
					title: `${data.wpSeoPage.seo.opengraphTitle}`,
					description: `${data.wpSeoPage.seo.opengraphDescription}`,
					images: [
						{
							url: `${data.wpSeoPage.seo.twitterImage.link}`,
							width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
							height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
							alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section>
					<Hero
						customTitle
						button1="primary-link-dark"
						button1Text={heroSection.button1.text}
						button1Link={heroSection.button1.link}
						button1Var="white"
						// button2="white-link-yellow"
						// button2Text="Pricing"
						// button2Link="#pricing"
						// button2Var="primary"
						button2={null}
						button3={null}
						backgroundImage={data.heroImg.gatsbyImage}
						title={heroSection.title}
						description={heroSection.description}
					/>
				</section>
				<Logos />
				{/* <section className="py-5 py-lg-7 bg-light-grey d-none d-lg-block">
					<Container className="p-5 bg-white">
						<Row className="justify-content-center gy-4">
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#intro"
								>
									Introduction
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#results"
								>
									Delivering results
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#pricing"
								>
									Pricing
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#approach"
								>
									Our approach
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#faq"
								>
									FAQ's
								</a>
							</Col>
						</Row>
					</Container>
				</section> */}
				<section id="intro" className="bg-light-grey py-5 py-lg-7">
					<Container>
						<Row className="align-items-center gx-lg-6 gx-xl-7">
							<Col lg={6} className=" mb-5 mb-lg-0 ">
								<div>{parse(creatingSection.title)}</div>
								<div
									className="d-lg-none mb-4"
									style={{ overflow: "hidden", borderRadius: "15px" }}
								>
									<GatsbyImage
										className="w-100"
										image={creatingSection.image.gatsbyImage}
										alt={creatingSection.image.altText}
									/>
								</div>
								<div>{parse(creatingSection.description)}</div>
								<Button
									className=" cta-btn w-100 d-none d-xl-inline-block w-md-auto mt-4 me-md-4 fs-5 white-link-yellow py-2 px-4 "
									variant="primary"
									href={creatingSection.button1.link}
								>
									{creatingSection.button1.text}
								</Button>
								{/* <Button
									className=" cta-btn w-100 w-md-auto mt-4 fs-5 white-link-yellow py-2 px-4 "
									variant="primary"
									href="#pricing"
								>
									Website pricing
								</Button> */}
							</Col>
							<Col className="d-none d-lg-block" lg={6}>
								<div style={{ overflow: "hidden", borderRadius: "15px" }}>
									<GatsbyImage
										className="w-100"
										image={creatingSection.image.gatsbyImage}
										alt={creatingSection.image.altText}
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				<section id="results" className="py-5 py-lg-7 bg-dark-grey">
					<Container className="mb-8">
						<Row className="gx-lg-6 gx-xl-7">
							<Col lg={6}>
								<div>{parse(weDeliverSection.title)}</div>
								<div>{parse(weDeliverSection.description)}</div>
								<Button
									className=" cta-btn w-100 w-md-auto mt-4 fs-5 primary-link-dark py-2 px-4 "
									variant="white"
									href={weDeliverSection.button1.link}
								>
									{weDeliverSection.button1.text}
								</Button>
							</Col>
							<Col lg={6} className="position-relative d-none d-lg-block pb-5">
								<div
									style={{ borderRadius: " 15px", overflow: "hidden" }}
									className="bg-white  ms-auto mt-4  w-80"
								>
									<GatsbyImage
										className="w-100 "
										image={weDeliverSection.image1.gatsbyImage}
										alt={weDeliverSection.image1.altText}
									/>
								</div>
								<div
									style={{ borderRadius: " 15px", overflow: "hidden" }}
									className=" ms-auto mt-8 h-40 w-40"
								>
									<GatsbyImage
										className="w-100 h-100"
										image={weDeliverSection.image3.gatsbyImage}
										alt={weDeliverSection.image3.altText}
									/>
								</div>
								<div
									style={{
										borderRadius: " 15px",
										boxShadow: "0px 3px 90px #00000079",
										overflow: "hidden",
									}}
									className="position-absolute start-0 bottom--20 ms-auto mt-8 h-80 w-40"
								>
									<GatsbyImage
										className="w-100 "
										image={weDeliverSection.image2.gatsbyImage}
										alt={weDeliverSection.image2.altText}
										objectPosition="top center"
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				{/* <div id="pricing">
					<PricingSection btnText="Talk to an expert" btnLink="#form" />
				</div> */}

				<div id="approach">
					<OASection
						buttonUrl={ourApproachSection.button.link}
						buttonText={ourApproachSection.button.text}
						imgHeight="60rem"
						image={ourApproachSection.image.gatsbyImage}
						imageAlt={ourApproachSection.image.altText}
						heading1={ourApproachSection.step1.title}
						heading2={ourApproachSection.step2.title}
						heading3={ourApproachSection.step3.title}
						heading4={ourApproachSection.step4.title}
						launch={ourApproachSection.step4.description}
						build={ourApproachSection.step3.description}
						design={ourApproachSection.step2.description}
						brief={ourApproachSection.step1.description}
					/>
				</div>
				<section className="pt-5 pt-lg-7  bg-light-grey">
					<Container>
						<Row id="form">
							<Col className="text-center">
								{" "}
								<div>{parse(bookAMeetingSection.title)}</div>
								<p>{bookAMeetingSection.description}</p>
							</Col>
						</Row>
						<Row>
							<Col className="d-xl-none">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "1150px" }}
									url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
								/>
							</Col>
							<Col className="d-none d-xl-block">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "700px" }}
									url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
								/>
							</Col>
						</Row>
					</Container>
				</section>
				{/* <section className="pt-5 pb-md-5 py-lg-7 bg-light-grey">
					<ReviewSection page="Our Web Services" />
				</section> */}
				<div id="faq">
					<Faq page="Web Design" />
				</div>
				<OSSection
					link3="/sustainable-web-design"
					text3="SUSTAINABLE WEBSITES"
					image={data.sideImg1.gatsbyImage}
					imageAlt={data.sideImg1?.altText}
				/>
			</Layout>
		</>
	);
};

export default WebDesignPage;
